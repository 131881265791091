import TagManager from 'react-gtm-module';

const isTrackingEnabled = () => {
  return process.env.REACT_APP_ENABLE_TRACKING === 'true';
};

export const trackingInitializerGTM = () => {
  if (isTrackingEnabled()) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM as string,
    });
  }
};
